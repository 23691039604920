import '../stylesheets/homepage';
import '../stylesheets/tailwind.css';

require('bootstrap-sass/assets/javascripts/bootstrap');
require('vendor/assets/components/jquery-ujs/src/rails');
require('js/application');
require('validate');
require('../../../gems/lp_analytics/lib/assets/javascripts/lp_analytics');
require('js/posthog_init');

require('js/header');
require('newsletter_form');
require('remote_helper');
require('login_modal');
require('modal_trigger');
require('modal_hooks');
require('ios_fix');
require('signup_modal');
require('freemium/freemium_welcome_modal');
require('js/homepage');
require('js/on_page_ready');

# TODO: WE NEED TO RETIRE THIS !!!
Application =
  config: {}
  module_names: []
  modules: {}
  onLoad: []
  module: (name, module) ->
    @module_names.push name unless @modules[name]?
    @modules[name] = @extend(module) if module
    @modules[name] ||= @extend {}

  init: (controller, action) ->
    # Run sitewide init code
    @module(module).site() for module in @module_names when @module(module).site?

    # Run onload code that is set on the current page
    @onLoad[key]() for _, key in @onLoad

    # Run controller specific init code
    if controller in @module_names
      controller_module = @module controller
      controller_module.controller() if controller_module.controller?
      controller_module[action]() if controller_module[action]?

    # Fire events
    @trigger 'site'
    @trigger controller if controller?
    @trigger "#{controller}##{action}" if action? && controller?

  on: (event, callback) ->
    $(this).on event, callback

  trigger: ->
    args = Array::slice.call(arguments, 1)
    $(this).trigger arguments[0], args

  extend: (obj) ->
    obj.on = @on unless obj.on
    obj.trigger = @trigger unless obj.trigger
    obj

  isPhone: ->
    $('body').append($("<div id='only-check' class='hidden-xs'/>"))
    value = !$('#only-check').is(':visible')
    $('#only-check').remove()
    return value

  isTablet: ->
    $('body').append($("<div id='only-check' class='hidden-sm'/>"))
    value = !$('#only-check').is(':visible')
    $('#only-check').remove()
    return value

  isMobile: ->
    @isPhone() or @isTablet()

  isDesktop: ->
    not @isMobile()

  isAndroid: ->
    /(Android)/g.test navigator.userAgent

  isIOS: ->
    navigator.platform? && /iPad|iPhone|iPod/g.test(navigator.platform)

  isSafari: ->
    /^(?!.*Chrome)(?=.*Safari).*/g.test navigator.userAgent

  isMac: ->
    /(Mac)/g.test navigator.userAgent

  initFromElement: (element) ->
    @init.apply(this, @parseElementData(element))

  parseElementData: (element) ->
    $el = $ element
    controller = ('_' + $el.data('controller')).replace /[_/]+([^_])/g, (match, capture) ->
      capture.toUpperCase()
    action = $el.data('action')
    return [controller, action]

  bindRRPToggle: () ->
    selectors = ['.concepts:visible', '.keyterms:visible']

    selectors.forEach (selector) ->
      $(selector).each (el) ->
        $el = $(selector + "[data-identifier='#{$(this).data('identifier')}']")
        classId = '.' + $el.data('type')
        identifier = $el.data('identifier')
        linkSelector = classId  + "-toggle-buttons[data-identifier='#{identifier}'] a"
        return unless $el.data('type')?

        $(linkSelector).off 'click'
        $(linkSelector).on 'click', (e) ->
          e.preventDefault()
          if $(this).text() == 'Show More'
            $el.trigger('destroy').css('max-height', 'none')
            $(linkSelector + '.more').hide()
            $(linkSelector + '.less').css('display', 'inline-block')
            $el.removeClass('with-clamp')
          else
            $(this).hide()
            $(linkSelector + '.more').css('display', 'inline-block')
            $el.css('max-height', '60px')
            $el.addClass('with-clamp')

        if $el.height() == +$el.css('maxHeight').replace('px', '')
          $(linkSelector + '.more').css('display', 'inline-block')
          $el.addClass('with-clamp')

  # This happens only on local. O.o??
  # Tablets trigger both AJAX & HTML calls to href.
  preventDoubleClickOnRemoteLink: ->
    unless LP.config.isDesktop
      $('a[data-remote=true]').on 'click', (e) ->
        e.preventDefault()
        $('input, textarea').blur() # close the tablet keyboard

window.LP = $.extend Application, (window.LP || {})

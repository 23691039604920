require('vendor/assets/components/jquery.cookie/jquery.cookie.js')

class FreemiumWelcomeModal
  welcomeModalShown: 'welcome_modal_shown'

  constructor: ({@path, @signInCount}) ->
    @render()

  shouldShow: ->
    return $.cookie(@welcomeModalShown) != @signInCount

  render: ->
    if @shouldShow()
      @getFreeMemberWelcomeModal()

  getFreeMemberWelcomeModal: ->
    $.get @path, @_showFreeMemberWelcome

  _showFreeMemberWelcome: (data) =>
    $('body').append data
    $('#free-member-welcome').modal('show')
    $.cookie @welcomeModalShown, @signInCount, path: '/'
    LP.module('Analytics').track('Served Free Member Welcome Modal')

LP.FreemiumWelcomeModal = FreemiumWelcomeModal

import posthog from 'posthog-js';

export function user_identify(distinct_id, user_properties) {
  posthog.identify(distinct_id, user_properties);
}

export function user_reset() {
  posthog.reset()
}

export function user_group(type, group_id, data) {
  posthog.group(type, group_id, data)
}

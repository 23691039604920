$('.lp-logo-search-wrap .lp-searchbar').click(function (e) {
  e.stopPropagation();
  $('.lp-logo-search-wrap').addClass('active');
});

$(document).click(function () {
  if ($('.lp-logo-search-wrap').hasClass('active')) {
    $('.lp-logo-search-wrap, .lp-logo-search-wrap .lp-searchbar').removeClass('active');
  }
});

$(document).ready(function () {
  if ($('#search-form.main-search').length > 0) {
    if ($('.lp-logo-search-wrap .lp-searchbar').val().length === 0) {
      $('.lp-searchbar').addClass('empty');
    } else {
      $('.lp-searchbar').removeClass('empty');
    }
  }
});

$('.lp-logo-search-wrap .lp-searchbar').blur(function () {
  if ($(this).val().length === 0) {
    $(this).addClass('empty');
  } else {
    $(this).removeClass('empty');
  }
});

$('.lp-searchbar').keydown(function () {
  if ($(this).val().length > 0) {
    $(this).removeClass('empty');
    $('.lp-header-clear-search').addClass('active');
  } else {
    $(this).addClass('empty');
    $('.lp-header-clear-search').removeClass('active');
  }
});

$('.lp-header-clear-search').click(function (e) {
  $('.lp-searchbar').val('').addClass('empty');
  if (window.location.pathname == '/search') {
    $('.lp-header-search-button').click();
  } else {
    $.ajax({
      type: $('#search-form').attr('method'),
      url: $('#search-form').attr('action'),
      data: { keywords: '' }
    });
  }
  $('.lp-header-clear-search').removeClass('active');
});

$('.lp-search-toggle').click(function (e) {
  e.stopPropagation();
  $('.lp-logo-search-wrap').toggleClass('active');
  $('#header-wrap').toggleClass('mobile-search-open');
  $('#header-wrap').removeClass('mobile-menu-open');
  $('.lp-logo-search-wrap .lp-searchbar').focus();
  $('#drop').prop('checked', false);
});

$('.lp-searchbar-close').click(function (e) {
  e.stopPropagation();
  $('.lp-logo-search-wrap').removeClass('active');
  $('#header-wrap').removeClass('mobile-search-open');
});

$('.main-menu-toggle').click(function (e) {
  e.stopPropagation();
  $('#header-wrap').toggleClass('mobile-menu-open');
  $('body').toggleClass('overflow-hidden');
  $('#header-wrap').removeClass('mobile-search-open');
  $('.lp-logo-search-wrap').removeClass('active');
});

$('.lp-teacher-menu-mobile-toggle').click(function () {
  $(this).toggleClass('open');
  $('.lp-teacher-menu').toggleClass('open');
});

$('.lp-header-search-filter').click(function (e) {
  e.stopPropagation();
  $(this).toggleClass('open');
});

$('.lp-nav-menu .toggle').click(function () {
  var navItem = $(this);
  if ($(navItem).hasClass('open')) {
    $(navItem).removeClass('open');
  } else {
    $('.lp-nav-menu .toggle').removeClass('open');
    $(navItem).addClass('open');
  }
});

$('.lp-nav-menu').on('mouseenter', LP.module('Popovers')._closeHowToPopoverOnSearchPage);

$('.lp-nav-menu li:not(#lp-mobile-username) .toggle').click(function () {
  var navItem = $(this);
  var scrollPos = $(navItem).position().top;
  $('.lp-nav-menu').animate(
    {
      scrollTop: scrollPos + 665
    },
    400
  );
});

$(document).click(function () {
  if ($('.lp-header-search-filter').hasClass('open')) {
    $('.lp-header-search-filter').removeClass('open');
  }

  if ($('.lp-logo-search-wrap').hasClass('active')) {
    $('.lp-logo-search-wrap').removeClass('active');
  }

  if ($('#header-wrap').hasClass('mobile-search-open')) {
    $('#header-wrap').removeClass('mobile-search-open');
  }
});

// This is footer related code, if more lines will come, a new file should be created

$('.footer-col-title').click(function () {
  $(this).toggleClass('open');
});

$(document).ready(function () {
  $('#email_subscription_email').val(LP.config.userEmail);
});

LoginModal = LP.module 'LoginModal'

LoginModal.site = ->
  $('body').on 'shown.bs.modal', '#register-modal, #login-modal, #step2-modal', ->
    $('input:text:visible:first', @).focus()

LoginModal.show = (e) ->
  if $('#login-modal').length
    e.preventDefault() if e
    $('#login-modal').modal('show')

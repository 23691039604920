IosFix = LP.module 'IosFix'

IosFix.site = ->
  if LP.isIOS()
    $('body, html').css(overflow: 'auto')

    # @see http://stackoverflow.com/questions/7970389/ios-5-fixed-positioning-and-virtual-keyboard
    $('input').on 'focus', ->
      unless $(@).parents('.off-canvas-menu').length
        $('#off-canvas-wrap').removeClass 'move-right'

    # To close the popovers on outside clicks !!
    # ex: "Get Full Access" popover for guests on Favorites, Save Collection.
    $('body').css(cursor: 'pointer')

/* global $ */
import dragula from 'dragula';

$(function ready() {
  $('.hero-nav-item[data-navname="discover"]').addClass('active');
  $('#discover-text').addClass('in');
  $('#discover-img').addClass('in');

  function slide(navName) {
    $('#hero-content').removeClass();
    $('#hero-content').addClass(navName);

    $('.hero-title.in').addClass('out');
    $('.hero-text.in').addClass('out');
    $('.hero-img.in').addClass('out');

    $('.hero-title').removeClass('in');
    $('.hero-text').removeClass('in');
    $('.hero-img').removeClass('in');

    $('.hero-nav').addClass('in-transition');

    const slideInTimeout = setTimeout(function slideInTimeout() {
      $(`#${navName}-title`).addClass('in');
      $(`#${navName}-text`).addClass('in');
      $(`#${navName}-img`).addClass('in');
    }, 500);

    const slideOutTimeout = setTimeout(function slideOutTimeout() {
      $('.hero-title').removeClass('out');
      $('.hero-text').removeClass('out');
      $('.hero-img').removeClass('out');
      $('.hero-nav').removeClass('in-transition');
    }, 1000);
  }

  function nextOne() {
    $('.hero-nav-item').each(function () {
      if ($(this).is('.active:last-child')) {
        var current = $(this);
        var upnext = $(this).parent().find('.hero-nav-item:first-child');
        window.myVar = setTimeout(function () {
          $(current).removeClass('active');
          $(upnext).addClass('active slowdown');
          const navName = $(upnext).attr('data-navname');
          slide(navName);
          nextOne();
        }, 8500);
      } else if ($(this).hasClass('active slowdown')) {
        var current = $(this);
        var upnext = $(this).next();
        window.myVar = setTimeout(function () {
          $(current).removeClass('active slowdown');
          $(upnext).addClass('active slowdown');
          const navName = $(upnext).attr('data-navname');
          slide(navName);
          nextOne();
        }, 20000);
      } else if ($(this).hasClass('active')) {
        var current = $(this);
        var upnext = $(this).next();
        window.myVar = setTimeout(function () {
          $(current).removeClass('active');
          $(upnext).addClass('active');
          const navName = $(upnext).attr('data-navname');
          slide(navName);
          nextOne();
        }, 8500);
      }
    });
  }

  nextOne();

  $('.hero-nav-item').click(function (e) {
    e.preventDefault();
    if ($(window).width() < 600) {
      $('html,body').animate(
        { scrollTop: $('.lpip-hp-hero-section').offset().top - 75 },
        'slow'
      );
    }
    $(this).addClass('active').siblings().removeClass('active');
    const navName = $(this).attr('data-navname');
    const slideInTimeout = '';
    const slideOutTimeout = '';
    clearTimeout(window.myVar);
    clearTimeout(slideInTimeout);
    clearTimeout(slideOutTimeout);
    slide(navName);
    nextOne();
  });

  $('.lpip-hp-inspire-section-option').click(function () {
    $('.lpip-hp-inspire-section-option').removeClass('active');
    $(this).addClass('active');
    if ($(this).hasClass('educator')) {
      $('.lpip-hp-section-actions').removeClass('active');
      $('.lpip-hp-section-actions.educator').addClass('active');
    } else if ($(this).hasClass('parent')) {
      $('.lpip-hp-section-actions').removeClass('active');
      $('.lpip-hp-section-actions.parent').addClass('active');
    } else {
      $('.lpip-hp-section-actions').removeClass('active');
      $('.lpip-hp-section-actions.admin').addClass('active');
    }
  });

  $('#collect-img, .drag-item').mouseenter(function (e) {
    e.preventDefault();
    const slideInTimeout = '';
    const slideOutTimeout = '';
    clearTimeout(window.myVar);
    clearTimeout(slideInTimeout);
    clearTimeout(slideOutTimeout);
  });

  $(document).on('mouseleave', '#collect-img.in:not(.still-active)', function (e) {
    e.preventDefault();
    setTimeout(function () {
      $(".hero-nav-item[data-navname='design']")
        .addClass('active')
        .siblings()
        .removeClass('active');
      const navName = $(".hero-nav-item[data-navname='design']").attr('data-navname');
      const slideInTimeout = '';
      const slideOutTimeout = '';
      clearTimeout(window.myVar);
      clearTimeout(slideInTimeout);
      clearTimeout(slideOutTimeout);
      slide(navName);
      nextOne();
    }, 4000);
  });

  $(document).on('mouseenter', '.lpip-hp-solution-section-option', function (e) {
    e.stopPropagation();
    $('.lpip-hp-solution-section-option').removeClass('active');
  });

  $('iframe').attr('id', 'lpip-hp-video');

  let $videoSrc;
  $videoSrc = $('#lpip-hp-video').attr('data-src');

  setTimeout(function () {
    $('#lpip-hp-video').attr('src', '');
  }, 500);

  $('.lp-video-launch').click(function () {
    $('html').addClass('video-modal-open');
    $('#lpip-hp-video').attr('src', $videoSrc);
  });

  $('#lpip-hp-video-box, #lpip-hp-close-video-box-x').click(function () {
    $('#lpip-hp-video').attr('src', '');
    $('html').removeClass('video-modal-open');
  });

  $('#lpip-hp-video-box-inner').click(function (e) {
    e.stopPropagation();
  });

  const startingScroll = $(window).scrollTop();

  if (startingScroll <= 500) {
    $('.hero-text-wrap').removeClass('hp-hide');
  } else {
    $('.hero-text-wrap').addClass('hp-hide');
  }

  $(window).scroll(function () {
    const scroll = $(window).scrollTop();
    if (scroll >= 500) {
      $('.hero-text-wrap').addClass('hp-hide');
    } else {
      $('.hero-text-wrap').removeClass('hp-hide');
    }
  });

  if ($(window).width() < 900) {
    var carousel = $('#carousel');
    var threshold = 150;
    var slideWidth = 310;
  } else if ($(window).width() < 1200) {
    var carousel = $('#carousel');
    var threshold = 150;
    var slideWidth = 400;
  } else if ($(window).width() < 1500) {
    var carousel = $('#carousel');
    var threshold = 150;
    var slideWidth = 500;
  } else {
    var carousel = $('#carousel');
    var threshold = 150;
    var slideWidth = 410;
  }

  $('#next').click(function () {
    shiftSlide(-1);
  });
  $('#prev').click(function () {
    shiftSlide(1);
  });

  function shiftSlide(direction) {
    if (carousel.hasClass('transition')) return;

    $(document).off('mouseup');
    carousel
      .off('mousemove')
      .addClass('transition')
      .css('transform', `translateX(${direction * slideWidth}px)`);
    setTimeout(function () {
      if (direction === 1) {
        $('.lpip-testimonial-member:first').before($('.lpip-testimonial-member:last'));
      } else if (direction === -1) {
        $('.lpip-testimonial-member:last').after($('.lpip-testimonial-member:first'));
      }
      carousel.removeClass('transition');
      carousel.css('transform', 'translateX(0px)');
    }, 700);
  }

  $('.lpip-testimonial-member').each(function () {
    const $text = $(this).find('.text');
    if ($text.text().length > 300) {
      $(this).addClass('has-long-text');
      $(this).append(
        '<div class="show-long-text">Show More <i class="fa fa-angle-down"></i></div>'
      );
    }
  });

  $('.show-long-text').click(function () {
    $('.lpip-testimonial-wrap').addClass('showing-long-text');
  });
});

// https://codepen.io/ettrics/pen/QbPEeg/
dragula([
  document.getElementById('1'),
  document.getElementById('2'),
  document.getElementById('3'),
  document.getElementById('4'),
  document.getElementById('5')
])
  .on('drag', function (el) {
    // add 'is-moving' class to element being dragged
    el.classList.add('is-moving');

    const parentDiv = document.querySelector('.lpip-cb');

    parentDiv
      .querySelector('.drag-inner-list')
      .setAttribute('class', 'drag-inner-list active');

    document
      .getElementById('collect-img')
      .setAttribute('class', 'hero-img in still-active');
  })

  .on('dragend', function (el) {
    document.getElementById('collect-img').setAttribute('class', 'hero-img in');

    // remove 'is-moving' class from element after dragging has stopped
    el.classList.remove('is-moving');

    // add the 'is-moved' class for 600ms then remove it
    window.setTimeout(function () {
      el.classList.add('is-moved');
      window.setTimeout(function () {
        el.classList.remove('is-moved');
      }, 600);
    }, 100);

    const parentDiv = document.querySelector('.lpip-cb');

    parentDiv.querySelector('.drag-inner-list').setAttribute('class', 'drag-inner-list');

    if (
      parentDiv.querySelector('#result-1') != null &&
      parentDiv.querySelector('#result-2') != null
    ) {
      document
        .getElementById('cb-result-1')
        .setAttribute('class', 'drag-item set-item show');
      document
        .getElementById('cb-result-2')
        .setAttribute('class', 'drag-item set-item show');
      document
        .querySelector('.lpip-cb')
        .setAttribute(
          'class',
          'lpip-cb lpip-box drag-column drag-column-in-progress has-4'
        );
    } else if (parentDiv.querySelector('#result-1') != null) {
      document
        .getElementById('cb-result-1')
        .setAttribute('class', 'drag-item set-item show');
      document
        .querySelector('.lpip-cb')
        .setAttribute(
          'class',
          'lpip-cb lpip-box drag-column drag-column-in-progress has-3'
        );
    } else if (parentDiv.querySelector('#result-2') != null) {
      document
        .getElementById('cb-result-2')
        .setAttribute('class', 'drag-item set-item show');
      document
        .querySelector('.lpip-cb')
        .setAttribute(
          'class',
          'lpip-cb lpip-box drag-column drag-column-in-progress has-3'
        );
    }
  });

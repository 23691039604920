SignupModal = LP.module 'SignupModal'
Analytics   = LP.module('Analytics')

SignupModal.showSignupModal = ->
  $('#signup-modal').on 'show.bs.modal', (e) ->
    Analytics.track('Saw_step1_modal')
    virtualPageView?('/subscription/new?panel=js-new-user')

  $('#signup-modal').on 'shown.bs.modal', (e) ->
    $('input:visible:first', @).focus()

  $('#signup-modal').modal('show')

  SignupModal.setupFormValidation()

  $('#signup-form').on 'ajax:error', (e, data, status, error) ->
    if data.status == 422
      error = JSON.parse(data.responseText).errors
      if error.email
        window.loginFormValidator.showErrors('user[email]': "This email #{error.email[0]}")

      $(window.loginFormValidator.submitButton).removeAttr('disabled').val('Get Free Trial')
      window.resetDoubleSubmitState($('#signup-form')[0])
    else # 500, etc
      window.location.reload()

SignupModal.trackSignupClick = ->
  $form     = $('#signup-form')
  $email    = $form.find('#user_email')
  $password = $form.find('#user_password')

  if $password.val().length > 0 && $email.val().length > 0
    Analytics.track('Clicked_step1_modal_signup_button')

SignupModal.setupFormValidation = ->
  window.loginFormValidator =
    $("#signup-form").validate({
      rules: {
        'user[email]': {
          required: true,
          email: true
        },
        'user[password]': {
          required: true,
          minlength: 5
        }
      },
      messages: {
        'user[email]': {
          email: "Please provide a valid email address."
        }
      },
      invalidHandler: SignupModal.trackSignupClick,
      submitHandler: SignupModal.submit
    })

SignupModal.submit = (form, e) ->
  SignupModal.trackSignupClick()
  $('input[type=submit]', $(form)).prop('disabled', true).val('Please wait...')
  doubleSubmitHandler(form)

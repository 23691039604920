ModalHooks = LP.module 'ModalHooks'

ModalHooks.site = ->
  ModalHooks.fixIOS()
  ModalHooks.keepModalOpen()
  ModalHooks.stackableZIndex()
  $(document).on 'shown.bs.modal', '.modal', ->
    ModalHooks.vertical_align @
    ModalHooks.checkModalBackdropBalance('shown')

  $(window).resize ->
    $('.modal').each ->
      ModalHooks.vertical_align @

ModalHooks.vertical_align = (element, animated = true, top) ->
  $element = $(element).children()
  top = Math.max(0, ($(window).height() - $element.outerHeight()) / 2) unless top
  return $element.animate margin: '20 auto', top: top, 'fast' if animated
  $element.css margin: '20 auto', top: top

ModalHooks.fixIOS = ->
  return unless LP.isIOS()
  $(document).on 'show.bs.modal', '.modal', ->
    setTimeout ->
      $('.modal-backdrop').css
        position: 'fixed'
        top: 0
        left: 0
        width: '100%'
        height: Math.max(
          document.body.scrollHeight, document.documentElement.scrollHeight,
          document.body.offsetHeight, document.documentElement.offsetHeight,
          document.body.clientHeight, document.documentElement.clientHeight
        )
    , 0

  $(document).on 'blur', 'input, textarea', ->
    setTimeout ->
      window.scrollTo document.body.scrollLeft, document.body.scrollTop
    , 0

ModalHooks.stackableZIndex = ->
  $(document).on 'show.bs.modal', '.modal', ->
    return if $(@).hasClass('js-no-stackable-modal')

    $modals = $('.modal.in:visible')
    if $modals.length > 0
      zIndex = Math.max.apply(Math, $modals.map(->
        parseInt($(@).css('z-index'))
      ))
      $(@).css(zIndex: zIndex + 10)
      setTimeout(->
        $('.modal-backdrop.in:last').css(zIndex: zIndex)
      , 100)

ModalHooks.keepModalOpen = ->
  $(document).on 'hidden.bs.modal', '.modal', ->
    ModalHooks.addModalOpenClass()
    ModalHooks.checkModalBackdropBalance('hidden')

ModalHooks.addModalOpenClass = ->
  $modals = $('.modal.in:visible')
  if $modals.length > 0
    # add the class because there are still open modals
    $('body').addClass('modal-open')

ModalHooks.checkModalBackdropBalance = (after) ->
  # adds or removes extra backdrops when needed
  if after == 'shown' && $('.modal.in:visible').length > $('.modal-backdrop').length
    $('body').append("<div class='modal-backdrop fade in'></div>")
  else if $('.modal.in:visible').length < $('.modal-backdrop').length
    $('.modal-backdrop:last').remove()

require('validate')

class EmailSubscriptionForm
  constructor: () ->
    @form = $('#new_email_subscription')
    @initValidation()

  initValidation: ->
    @form.validate
      rules:
        'email_subscription[email]':
          required: true

      submitHandler: (form) =>
        @form.find('.btn').prop('disabled', true)
        doubleSubmitHandler form, @submitForm

      invalidHandler: (form, validator) =>
        @submitForm() if validator.numberOfInvalids() is 1 and (validator.errorMap['email_subscription[email]'] || '').indexOf('Did you mean') is 0

      errorElement: 'p'
      errorClass: 'help-block'
      errorPlacement: (error, element) =>
        container = element.closest('.form-group')
        container.children('.help-block').remove()
        error.appendTo container
      highlight: (element, errorClass, validClass) =>
        $(element).closest('.form-group').addClass 'has-error'

      unhighlight: (element, errorClass, validClass) =>
        $(element).closest('.form-group').removeClass 'has-error'

  submitForm: =>
    $.ajax
      url: @form.prop('action')
      type: 'POST'
      data: @form.serialize()
      dataType: 'json'
      cache: false
      success: @signupSuccess
      error: @signupError
    false

  # server side success.
  signupSuccess: (data, status, xhr) =>
    resetDoubleSubmitState @form
    @form.find('.btn').prop('disabled', false)
    @form.children('.help-block').remove()
    $(data.page).modal('show').appendTo $('body')

  signupError: (data) =>
    resetDoubleSubmitState @form
    @form.find('.btn').prop('disabled', false)
    @showFormErrors($.parseJSON(data.responseText))

  # show form errors helper. get jquery.validate to do our bidding.
  showFormErrors: (errors) =>
    @displayErrorMessage(field, msgs[0]) for field, msgs of errors

  displayErrorMessage: (field, error) =>
    container = $("[name='email_subscription[#{field}]']").closest('.form-group')
    error = $("<p id='email_subscription_#{field}-error' class='help-block'>#{field} #{error}</p>")
    container.children('.help-block').remove()
    error.appendTo container.addClass('has-error')

new EmailSubscriptionForm() if $('#new_email_subscription').length

RemoteHelper = LP.module 'RemoteHelper'
RemoteHelper.ALERT_AUTOCLOSE_TIME = 9000

RemoteHelper.site = ->
  return if @initialized
  RemoteHelper.init()

RemoteHelper.init = ->
  @initialized = true
  this.init_ajax_forms()
  this.init_ajax_links()
  this.init_popup_links()

RemoteHelper.loadingEl = ->
  $('<i class="fa-solid fa-arrows-rotate fa-spin" />')

RemoteHelper.init_ajax_links = ->
  $(document).on 'ajax:beforeSend', 'a[data-remote=true]', (e) ->
    $link = $(this)
    if ($link.hasClass('disabled'))
      return false
    $link.addClass('disabled') # disable the button to prevent multiple clicks
    $elements = $link.data('remote-helper-elements')
    unless $elements
      $loading = RemoteHelper.loadingEl().appendTo($link)
      $elements = loading: $loading
      $link.data 'remote-helper-elements', $elements

    $elements = $(this).data 'remote-helper-elements'
    $elements.loading.show()

  $(document).on 'ajax:complete', 'a[data-remote=true]', RemoteHelper.ajax_complete_callback
  $(document).on 'ajax:success', 'a[data-remote=true]', RemoteHelper.ajax_success_callback
  $(document).on 'ajax:error', 'a[data-remote=true]', RemoteHelper.ajax_error_callback

  $(document).on 'ajax:display:flash', 'a[data-remote=true]', (e, messages, alert) ->
    options = $(e.currentTarget).data('flashMessage')
    if options.render_to
      $flash_message_container = $(options.render_to)
    else
      $flash_message_container = if $('.panel-review').length then $('.panel-review') else $('#content-header')
    $('.alert', $flash_message_container).remove()
    $flash_message_container.prepend RemoteHelper.build_flash_alert_display(messages, alert || options.alert)
    RemoteHelper.autoclose_alert($flash_message_container, options.autocloseTime) if options.autoclose?

RemoteHelper.init_ajax_forms = ->
  $(document).on 'ajax:beforeSend', 'form[data-remote=true]', (e) ->
    $form = $(this)
    $elements = $form.data('remote-helper-elements')
    $submit = $(":submit", $form)
    $submit.prop("disabled", true)

    unless $elements
      $messages_container = $('<div class="remote-messages"></div>').prependTo($form).hide()
      if $form.find('.fa.loading-spinner')[0]
        $loading = $form.find('.fa.loading-spinner')
      else
        $loading_container = if $submit.length then $submit[0] else $form
        unless $form.data('withoutSpinner')?
          $loading = RemoteHelper.loadingEl().insertAfter($loading_container)

      $elements =
        loading: $loading,
        messages_container: $messages_container
      $form.data('remote-helper-elements', $elements)

    $elements.loading?.show()
    $elements.messages_container.html('').hide()

  $(document).on 'ajax:complete', 'form[data-remote=true]', RemoteHelper.ajax_complete_callback
  $(document).on 'ajax:success', 'form[data-remote=true]', RemoteHelper.ajax_success_callback
  $(document).on 'ajax:error', 'form[data-remote=true]', RemoteHelper.ajax_error_callback

  $(document).on 'ajax:error:display ajax:success:display', 'form[data-remote=true]', (e, messages, $messages) ->
    $elements = $(this).data 'remote-helper-elements'
    if $messages && $messages.html()
      $elements.messages_container.html($messages).show()
      if e.type is 'ajax:success:display'
        $elements.messages_container.siblings().hide('slide')

  $(document).on 'ajax:display:flash', 'form[data-remote=true]', (e, messages, alertType) ->
    options = $(e.currentTarget).data('flashMessage')
    $(@).closest('.modal.in').modal('hide') unless options.keepModal?
    if options.render_to
      $flash_message_container = $(options.render_to)
    else
      $flash_message_container = if $('.panel-review').length then $('.panel-review') else $('#content-header')

    $('.alert', $flash_message_container).remove()
    $flash_message_container.prepend RemoteHelper.build_flash_alert_display(messages, alertType || options.alert)
    RemoteHelper.autoclose_alert($flash_message_container, options.autocloseTime) if options.autoclose?

RemoteHelper.init_popup_links = ->
  $('body').on 'click.popup-helper.data-api', 'a[data-popup=true]', (e) ->
    e.preventDefault()
    return if $(this).attr('loading')
    if $('#popup-modal').length < 1
      $('body').append($('<div id="popup-modal" class="modal fade"/>'))
    $modal   = $("#popup-modal")
    $a       = $(this)
    $loading = RemoteHelper.loadingEl()
    data     = $a.data()
    $a.append($loading) unless data.noSpinner
    $a.attr('loading', 'true')
    $modal.html('')

    $.get($a.attr('href'), (data) ->
      if data.indexOf('modal-body') is -1
        $('<div class="modal-dialog"><div class="modal-content"><div class="modal-header clearfix"><button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button></div><div class="modal-body"></div></div></div>').appendTo $modal
        $('.modal-body', $modal).append data
        $('h1.title', $modal).appendTo $('.modal-header', $modal)
        $('h1.title', $modal).replaceWith ->
          '<h3 class="text-primary-dark">' + $(this).html() + '</h3>'
      else
        $modal.append data
      $modal.modal()
      $loading.remove()
      $a.removeAttr('loading')
    ).fail (xhr) ->
      window.location.reload() if xhr.status == 500

# helper methods
RemoteHelper.autoclose_alert = (container = '#content-header', autocloseTime = 0) ->
  window.clearTimeout @alertTimer
  @alertTimer = window.setTimeout (->
    $('.alert', $(container)).slideUp(600, -> $(@).remove())
  ), (+autocloseTime || RemoteHelper.ALERT_AUTOCLOSE_TIME)

RemoteHelper.parse_response_messages = (xhr) ->
  errors = []
  try
    errors = $.parseJSON(xhr.responseText)
    errors = errors.error if errors.hasOwnProperty('error')
    errors = [errors] if typeof errors is 'string'
  return errors

RemoteHelper.build_messages_display = (messages, css_class) ->
  return null unless messages && messages.length
  $ul = $('<ul></ul>')
  $.each messages, (i, message) ->
    return true if typeof message is 'object'
    $ul.append "<li>#{message}</li>"
  $('<div></div>').html($ul).addClass "alert alert-#{css_class}"

RemoteHelper.build_flash_alert_display = (messages, css_class = 'success', additionalClases = 'mb0') ->
  return null unless messages && messages.length
  $alert = $('<div>', class: "alert alert-#{css_class} alert-dismissible #{additionalClases}" )
  $alert.append $('<button>', class: 'close','data-dismiss': 'alert').html($('<span>', 'aria-hidden': 'true').html('&times;').add $('<span>', class: 'sr-only', text: 'Close'))
  $.each messages, (i, message) ->
    return true if typeof message is 'object'
    $alert.append $('<span>').html(message)
  $alert

RemoteHelper.ajax_success_callback = (e, data, status, xhr) ->
  unless $(e.currentTarget).data('customCallbacks')?
    messages = RemoteHelper.parse_response_messages xhr
    if $(e.currentTarget).data('flashMessage')?
      $(this).trigger 'ajax:display:flash', [messages]
    else
      $messages = RemoteHelper.build_messages_display(messages, 'success')
      $(this).trigger 'ajax:success:display', [messages, $messages]

RemoteHelper.ajax_error_callback = (e, xhr, status, error) ->
  unless $(e.currentTarget).data('customCallbacks')?
    errors = RemoteHelper.parse_response_messages(xhr)
    if $(e.currentTarget).data('flashMessage')?
      $(this).trigger 'ajax:display:flash', [errors, 'danger']
    else
      $errors = RemoteHelper.build_messages_display(errors, 'danger')
      $(this).trigger 'ajax:error:display', [errors, $errors]

RemoteHelper.ajax_complete_callback = (e) ->
  unless $(e.currentTarget).data('customCallbacks')?
    $elements = $(this).data('remote-helper-elements')
    $elements.loading.hide() if $elements
    $(this).removeClass('disabled')
    if $(this).find(":submit").length
      $(this).find(":submit").blur().prop('disabled', false)

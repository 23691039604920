require 'vendor/assets/javascripts/bootstrap3-typeahead'

SearchBar = LP.module 'SearchBar'

SearchBar.site = ->
  autoFocusSearchBar()
  initSearchBar()

initSearchBar = ->
  $('.js-search-keywords').typeahead
    source: (query, process) ->
      $.getJSON LP.config.search_autocomplete_path, term: query, (data) ->
        if Array.isArray(data)
          process data
    updater: (item) ->
      this.$element.val item
      this.$element.parents("form").submit()
      item
    menu: "<ul class=\"typeahead dropdown-menu dropdown-lp-search\"></ul>"
    autoSelect: false

  $('.search-filter-item').on 'click', ->
    $searchForm = $(@).closest('.lp-search.main-search')
    $searchForm.attr 'action', $(@).data('action')
    $searchForm.children('.js-search-keywords').attr 'placeholder', $(@).data('placeholder')
    $searchForm.children('.lp-searchbar-label').text($(@).data('placeholder'))
    $(@).closest('ul').find('li').removeClass 'active'
    $(@).closest('li').addClass 'active'
    $(@).closest('.lp-header-search-filter').children('.search-filter-icon').removeClass().addClass("search-filter-icon fa-solid #{$(@).data('icon')}")
    $searchForm.find('.search-filter-dropdown .search-filter-item').removeClass('selected')
    $(@).addClass('selected')

SearchBar.clearSearch = ->
    $("#keywords").val('').addClass('empty')
    $(".lp-header-clear-search").removeClass('active')

autoFocusSearchBar = ->
  if onSearchAndDirectory() and not LP.config.logged_in
    $searchField = $('.js-search-keywords')
    $searchField.focus() unless $searchField.val()

onSearchAndDirectory = ->
  $('body').data('controller') in ['searches', 'directory']

import { user_identify, user_reset, user_group } from '../../../../../app/webpacker/js/posthog_analytics.js';

Analytics = LP.module("Analytics")

Analytics.site = ->
  # Data API for tracking clicks for analytics
  $('body').on 'click', '[data-track-click]', ->
    Analytics.track $(@).data('track-click'), { label: $(@).data('track-label') }
    return

  $(document).ready ->
    ga? ->
      # save clientId so we can use for server-side event tracking
      clientId = ga.getAll()[0].get('clientId')
      LP.config.ga_client_id = clientId

# Notes on KISSmetrics:
# _kmq is initialized with our api key and the user is identified in the HEAD
#   see: app/views/shared/_kissmetrics_js.html.erb
#
# _kmq is not initialized for the 'test' environment so all references
# to _kmq should be tested


# track lets you record the actions your users perform.
#
# event - String
#   The name of the event you’re tracking. It is recommended to use
#   human-readable names like 'Played Song' or 'Updated Status'.
#
# properties - Object, optional
#   A dictionary of properties for the event. If the event
#   was 'Added to Cart', it might have properties like price and productType.
#
Analytics.track = (event, properties={}) ->
  # send to out analytics track endpoint for server side processing
  $.ajax
    url: LP.config.analytics_track_path
    data: { analytics: { event: event, properties: properties, client_id: LP.config.ga_client_id } }

  # send to KISSmetrics
  _kmq?.push ['record', event, properties]

  # send to GTM dataLayer
  gtm_data =
    "event": "lp.event"
    "lp.event.action": event
    "lp.event.category": properties.category or 'All'
  gtm_data["lp.event.label"] = properties.label if properties.label?
  gtm_data["lp.event.value"] = properties.value if properties.value?
  dataLayer?.push gtm_data

# trackForm is a helper that binds a track call to a form submission.
#
# form - String
#   ID of the form element.
#
# event - String
#   The name of the event you’re tracking. It is recommended to use
#   human-readable names like 'Played Song' or 'Updated Status'.
#
Analytics.trackForm = (form, event) ->
  # only KISSmeterics form submit tracking is needed.
  _kmq?.push ['trackSubmit', form, event]

Analytics.trackVirtualPageSuccess = () ->
  if $.cookie('lpss') == 'success'
    virtualPageView?('/subscription/success')
  $.removeCookie('lpss', path: '/')

# PostHog events
Analytics.trackSignIn = (distinct_id, data) ->
  user_identify(distinct_id, data)

Analytics.trackReset = () ->
  user_reset()

Analytics.group = (type, group_id, data) ->
  user_group(type, group_id, data)

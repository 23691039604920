require('../../..//vendor/assets/components/jquery-validation/dist/jquery.validate.min.js');
require('../../../vendor/assets/javascripts/jquery.metadata.js');

window.doubleSubmitHandler = (form, submitHandler = null) ->
  unless $(form).data('beenSubmitted')
    $(form).data('beenSubmitted', true)
    if submitHandler then submitHandler() else $(form).trigger 'submit.rails'
  false

window.resetDoubleSubmitState = (form) ->
  $(form).data('beenSubmitted', false)

# Easily disable double submit on a form:
# $('#form').preventDoubleSubmit();
$.fn.preventDoubleSubmit = ->
  $(this).submit ->
    if $(@).data('beenSubmitted') is true
      false
    else
      $(@).data('beenSubmitted', true)

ValidateDataApi = LP.module 'ValidateDataApi'
ValidateDataApi.site = ->
  url_regexp = /((?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/

  if (!!$.prototype.metadata)
    # configure metadata to get json data form the 'metadata' attribute
    # don't want to conflict with html5 data attributes
    $.metadata.setType('attr', 'metadata')

  $.validator.addMethod "validate_url_liberally", ((value, element) ->
    if value is '' then true else url_regexp.test(value)
  ), "Please enter a valid URL."

  $.validator.addClassRules 'liberal-url',
    required: true,
    url: false,
    validate_url_liberally: true

  $.validator.addClassRules 'optional-liberal-url',
    required: false,
    url: false,
    validate_url_liberally: true

  $.validator.addMethod 'validate_multi_email', (value, element) ->
    # to use the built in email validator ($.validator.methods.email)
    # need to create a fake optional method on this.
    fakeThis =
      optional: (element) ->
        return false

    all_emails = value.split(',')
    valid_emails = $.grep value.split(','), (email) ->
      email = email.replace(/^\s+|\s+$/g, '')
      $.validator.methods.email.call(fakeThis, email)

    valid_emails.length is all_emails.length
  , 'Please enter a valid email address.'

  $.validator.addClassRules 'multi-email',
    validate_multi_email: true

  $.validator.addMethod 'validate_phone', (phone_number, element) ->
    # strip out formatting characters so regex is easier to write
    phone_number = phone_number.replace(/(\s+|[-\.()]+)/g, "")
    @optional(element) or phone_number.length > 9 and phone_number.match(/^\+?(?:[0-9] ?){6,20}(x\d+)?$/)
  , "Please specify a valid phone number"

  $.validator.addClassRules 'tel',
    validate_phone: true

  $.validator.addMethod 'validate_cc_number', (value, element) ->
    $.payment.validateCardNumber(value)
  , 'Please enter a valid credit card number'

  $.validator.addClassRules 'cc-number',
    validate_cc_number: true

  $.validator.addMethod 'validate_video_url', (value, element) ->
    value.match /^https?:\/\/(www\.)?(vimeo|youtube|ted|embed\.ted)\.com/
  , 'Please enter a valid YouTube, Vimeo or Ted URL'

  $.validator.addMethod 'edit_collection_title_regex', (value, element) ->
    !/^My Collection\d+$/.test(value)
  , 'Try adding a more descriptive title'

  $.validator.addClassRules 'video-url',
    required: true,
    validate_video_url: true

  $.validator.addMethod 'summernoteContentLength', (value, element, param) ->
    $value = $("<p>#{value}</p>")
    # In custom errors you don't have access to the new values' length.
    $(element).data('future-length', $value.text().length)
    $value.text().length >= param
  , "Please enter at least {0} characters."

  $.validator.addMethod 'validate_cc_expiry', (value, element) ->
    $.payment.validateCardExpiry($(element).payment('cardExpiryVal'))
  , 'Please enter a valid expiration date'

  $.validator.addClassRules 'cc-exp',
    validate_cc_expiry: true

  $.validator.addMethod 'validate_cc_cvc', (value, element) ->
    $.payment.validateCardCVC(value)
  , 'Please enter a valid security code'

  $.validator.addClassRules 'cc-cvc',
    validate_cc_cvc: true

  $.validator.addMethod 'filesize', (value, element, param) ->
    @optional(element) or !element.files? or (element.files[0].size <= param)
  , (param, element) ->
    sizeInMB = (param / (1024 * 1024)).toFixed()
    "The file size of the uploaded file must be less than #{sizeInMB}MB"

  $.validator.addMethod 'filename', (value, element, param) ->
    if param.typesForMyMedia.length
      fileType = element.files[0] && element.files[0].type.split('/')[0]
      return true if param.typesForMyMedia.includes(fileType)
    return true if @optional(element)
    (return true if value.match(new RegExp(pattern, 'i'))) for pattern in param.allowedExtensions
    false
  , 'The file is not one of the allowed formats.'

  $.validator.addMethod 'summernoteRequired', (value, element) ->
    !$(element).summernote('isEmpty')
  , 'Please add content to your page.'

  # Make it work for bootstrap forms
  $.validator.setDefaults
    errorElement: 'p'
    errorClass: 'help-block'
    # don't ignore chosen :hidden selects or validation doesn't work
    ignore: ':hidden:not(.chzn-done, .star_rating), :hidden > select.chzn-done'
    submitHandler: (form) ->
      if $(form).data('remote') is undefined
        $('input[type=submit]', $(form)).val('Please wait...')
        window.doubleSubmitHandler(form)
      else
        $(form).trigger 'submit.rails'
      false
    invalidHandler: (event, validator) ->
      # add change handler to plain selects and chosen selects to force re-validation
      $form = $(event.target)
      $form.on 'change', 'select', (e) ->
        $form.validate().element($(@))
      $form.find('.chzn-done').each ->
        $(@).chosen().change ->
          $form.validate().element($(@))
    errorPlacement: (error, element) ->
      # for .input-group and .check_boxes show the errors outside the wrapper
      if element.closest('.input-group').length
        container = element.closest('.input-group').parent()
      else if element.is(':radio, :checkbox')
        container = element.closest('.form-group')
      else
        container = element.parent()

      # don't show double errors for groups of controls
      error.appendTo container if container.children('.help-block:visible').length is 0

    highlight: (element, errorClass, validClass) ->
      if $(element).is(':radio, :checkbox')
        $(element).closest('.checkbox').addClass 'has-error'
      else
        $(element).closest('.form-group').addClass 'has-error'

    unhighlight: (element, errorClass, validClass) ->
      if $(element).is(':radio, :checkbox')
        $(element).closest('.checkbox').removeClass 'has-error'
      else
        $(element).closest('.form-group').removeClass 'has-error'

  $('form[data-validate~=validate]').each ->
    $(@).validate()
    $(@).find('[type=submit][data-disabled]').each ->
      $(@).prop('disabled', $(@).data('disabled'))

  # For forms loaded dynamically
  $(document).on 'ajaxSuccess', ->
    $('form[data-validate~=validate]').each ->
      $(@).validate()
      $(@).find('[type=submit][data-disabled]').each ->
        $(@).prop('disabled', $(@).data('disabled'))
